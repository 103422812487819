import { Injectable } from '@angular/core';


// Interface
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppService } from 'app/services/app.service';

@Injectable()
export class ClientProvider {
  constructor(public http: HttpClient, private appService: AppService) { }

  public create(form: any): Observable<any> {
    const suscription = this.appService.getViewSuscription();
    return this.http.post(environment.ENDPOINT + environment.SERVICES.CLIENT, {
      ...form,
      suscription: suscription || form.suscription
    });
  }

  public getList(): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.CLIENT_LIST}?suscription=${this.appService.getViewSuscription()}`);
  }

  public get(rut: string): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.CLIENT}/${rut}?suscription=${this.appService.getViewSuscription()}`);
  }

  public update(form: any): Observable<any> {
    const suscription = this.appService.getViewSuscription();
    return this.http.put(`${environment.ENDPOINT}${environment.SERVICES.CLIENT}/${form.rut}`, {
      ...form,
      suscription: suscription || form.suscription
    });
  }
}
